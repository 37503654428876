import React from 'react'

const SubCart = () => {
  return(
    <div>
      sub cart
    </div>
  )
}

export default SubCart
