import React, { useRef, useState, useEffect } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { window, document } from "browser-monads"
import useBreakpoint from "~utils/useBreakpoint"
import Helmet from "react-helmet"
import BlockContent from '@sanity/block-content-to-react'

import Section from "~components/global/section"
import Layout from "~components/layout"
import Button from "~components/global/button"
import ProductBadge from "~components/global/productBadge"
import Ticker from "~components/modules/ticker"
import formatColors from "~utils/formatColors"
import smoothscroll from 'smoothscroll-polyfill';
import { SelectButton } from "~components/global/formElements"
import ProductBadgeMini from "~components/global/productBadgeMini"
import { X, HeavyPlus } from "~components/global/svg"
import SEO from "~components/seo"

import SubCart from '~components/cart/subCart'

const SubscribeGroup = ({ groupContent }) => {
  let g = groupContent
  const products = []
  g.categories.forEach(cat => cat.products.forEach(p => products.push(p)))

  const [currentProduct, setCurrentProduct] = useState(false)
  const [overlay, setOverlay] = useState(false)

  const productColors = currentProduct ? formatColors(currentProduct.content.main.basic) : {}
  const { mobile, tablet } = useBreakpoint()

  useEffect(() => {
    smoothscroll.polyfill()
    if(products.length > 0){
      setCurrentProduct(products[0])
    }
  }, [])

  return ( currentProduct &&
    <>
    <SEO title="Subscribe & Save" pagePath='/subscribe' />
    <Helmet
    bodyAttributes={overlay && {
      class: 'no-scroll'
    }}
    htmlAttributes={overlay && {
      class: 'no-scroll'
    }}
    />

    <div className="sub-builder__group sub-group">
      <div className="sub-group__image">
        <h2 className="m-caps">{g.title}</h2>
        <Img fluid={currentProduct.content.main.basic && currentProduct.content.main.basic.productImages.length && {...currentProduct.content.main.basic.productImages[0].asset.fluid, aspectRatio: 1}} />
        {!tablet &&
          <Ticker text={currentProduct.content.main.basic && currentProduct.content.main.basic.tagline} bg={productColors.safe[0]} textColor={productColors.safe[1]}>
            {currentProduct.content.main.basic && currentProduct.content.main.basic.tagline}
          </Ticker>
        }
      </div>

      {mobile &&
      <div className="sub-builder__pick">
        <h5 className="s-caps">Add to a {groupContent.catTitle.slice(0,-1)} your subscription</h5>
        <SelectButton
          primaryIcon={
            <div className="pick-icon">
              <HeavyPlus />
            </div>
          }
          onClick={() => setOverlay('product-types')}>
          {groupContent.catTitle.slice(0,-1)} picker
        </SelectButton>
      </div>
      }

      <div className={`sub-product-types ${overlay === 'product-types' && tablet ? 'sub-product-types--visible' : '' }`}>
        {mobile &&
          <button className="sub-product-types__close" onClick={() => setOverlay(false)}><X /></button>
        }
        <h3 className="m-caps">{g.catTitle}</h3>
          {g.categories.map(category => category.title !== 'Sample Packs' && (
            <>
            <h4 className="s-caps">{category.title}</h4>
            <div className="sub-product-types__products">
            {category.products.length && category.products.map(product => product.content.main.basic && (
              <ProductBadge
                name={product.content.main.title}
                colors={product.content.main.basic.iconColors.map(c => c.color.hex)}
                rating={product.content.main.basic.strength}
                onHover={() => !tablet && setCurrentProduct(product)}/>
            ))}
            </div>
            </>
          ))
          }
      </div>

    </div>
    </>
  )
}

const Subscribe = ({ data }) => {

  const groups = data.sanitySubscribe.groups
  const builderRef = useRef()
  const { mobile, tablet } = useBreakpoint()

  function scrollDown(e){
    e.preventDefault()
    let offset = 0 - (document.body.getBoundingClientRect().top - builderRef.current.getBoundingClientRect().top)
    offset = offset - (tablet ? 50 : 80)
    window.scrollTo({top: offset, behavior: 'smooth'})
  }

  return(
    <Layout>
      <Section name="subscribe-header">
        <div className="subscribe-header__text">
          <h1 className="l-sans">{data.sanitySubscribe.title}</h1>
          <div>
            <BlockContent blocks={data.sanitySubscribe._rawText} />
          </div>
          <Button onClick={e => scrollDown(e)} color="orange" arrow="down">
            Get Started
          </Button>
        </div>
        <div className="subscribe-header__illo">
          <Img fluid={null} />
        </div>
      </Section>
      <Section name="sub-builder">
        <span className="sub-builder__divider" ref={builderRef}/>
        <div className="sub-builder__groups">
          {groups.map(group => (
            <SubscribeGroup groupContent={group} />
          ))}
        </div>

        <div className="sub-cart">
          <h4 className="m-caps">Build your subscription</h4>
          <SubCart />
        </div>
      </Section>
    </Layout>
  )
}

export const query = graphql`
query{
  sanitySubscribe(id: {eq: "-ba610022-3468-58fa-bcba-5a77f742a56e"}) {
    slug {
      _type
      current
    }
    title
    _rawText
    groups {
      categories {
        title
        products {
          content {
            main {
              title
              variants {
                content {
                  main {
                    title
                  }
                  shopify {
                    variantId
                  }
                }
              }
              basic {
                strength
                tagline
                productImages {
                  asset {
                    fluid {
                      aspectRatio
                      base64
                      sizes
                      src
                      srcSet
                      srcSetWebp
                      srcWebp
                    }
                  }
                }
                iconColors {
                  color {
                    hex
                  }
                }
                primaryColor {
                  hex
                }
                primaryColorOverride {
                  hex
                }
                secondaryColor {
                  hex
                }
                secondaryColorOverride {
                  hex
                }
                highlightColor {
                  hex
                }
              }
            }
          }
        }
      }
      catTitle
      title
    }
  }
}
`

export default Subscribe
